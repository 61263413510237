<template>
  <div>
    <addDetail :noticeForm="noticeForm"></addDetail>
  </div>
</template>

<script>
import api from "@/api/guildManageapi";
import addDetail from "./components/addDetail";
export default {
  name: "noticeedit",
  components: { addDetail },
  data () {
    return {
      noticeForm: {},
      announce_id: null,
    };
  },
  created () {
    this.$store.commit("layouts/setPathList", [{ name: '公告管理', path: '/backstage/noticepage' }, { name: '编辑' }]);
    this.$store.commit("layouts/changeBreadcrumb", true)
  },
  destroyed () {
    this.$store.commit("layouts/changeBreadcrumb", false);
  },
  watch: {
    $route: {
      handler: function ({ query }) {
        this.announce_id = query.announce_id;
        this._getNoticeDetail();
      },
      immediate: true,
    },
  },
  methods: {
    async _getNoticeDetail () {
      const data = await api.getNoticeDetail({ announce_id: this.announce_id });
      console.log(data);
      if (data.status_code === 200) {
        this.noticeForm = data.message
        this.noticeForm.announce_id = this.announce_id
      }
    },
  },
};
</script>

<style>
</style>